import useSWR from 'swr';
import { apiGet } from '../helper';
import { useAuthContext } from './authContext';

export default function useUser() {
    let { isAuthenticated } = useAuthContext();

    let path = isAuthenticated() ? `/user` : null;

    const { data, isLoading, mutate, error } = useSWR(path, apiGet);

    return [data, isLoading, mutate, error];

    // let { isAuthenticated, auth } = useAuthContext();
    // // console.log('useUser authdata:', auth)
    // const { loading, error, data, refetch } = useQuery(GET_USER, {
    //     variables: {
    //         id: auth?.uid,
    //     },
    //     skip: !isAuthenticated(),
    // });

    // let user = data ? data?.users_by_pk : undefined;

    // return [user, loading, refetch, error];
}
